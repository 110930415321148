import { CACHE_DATA, domain } from 'utils/constants'
import ROUTES from 'utils/constants/routesConstant'
import { ItemType } from 'utils/types'

export const convertPrice = (price?: number) => {
  if (!price) return
  let stringPrice = price.toString().split('').reverse()
  const newString = []

  while (stringPrice.length > 0) {
    const x = [stringPrice[0], stringPrice[1], stringPrice[2]]
    for (let i = 0; i < x.length; i++) {
      if (i === 2 && !!x[i]) {
        newString.push('.' + x[i])
      } else newString.push(x[i])
    }
    stringPrice = stringPrice.slice(3)
  }
  const totalString = newString.reverse()
  return totalString.join('')[0] === '.' ? totalString.join('').slice(1, totalString.join('').length) : totalString.join('')
}

export const onClickCard = ({ history, type, id, item }: { history: any; type: string; id: string; item: ItemType }) => {
  const oldData = localStorage.getItem(CACHE_DATA)
  let parsedData: ItemType[] = []
  if (oldData && oldData?.length) {
    parsedData = JSON.parse(oldData)
  }
  if (item) {
    const listId = parsedData.map((i) => i._id)
    if (!listId.includes(item._id)) {
      const newData = [...parsedData, ...[item]]
      localStorage.setItem(CACHE_DATA, JSON.stringify(newData))
    }
    const route = ProductType(item?.type) === 'HOUSE' ? ROUTES.HOUSE : ROUTES.VEHICLE
    history.push({ pathname: `${route}/${id}` })
  }
}

export const generateMediaLink = (url: string) => {
  if (!url) return ''

  if (url.includes(domain)) {
    return url
  }

  return domain + '/' + url
}

export const getDateString = (date?: string | Date, format?: string) => {
  if (!date) return
  const today = new Date(date)
  const yyyy = today.getFullYear()
  let mm: string | number = today.getMonth() + 1 // Months start at 0!
  let dd: string | number = today.getDate()

  if (dd < 10) dd = '0' + dd
  if (mm < 10) mm = '0' + mm
  if (format === 'yyyy-mm-dd') return yyyy + '-' + mm + '-' + dd
  const formattedDate = dd + '/' + mm + '/' + yyyy
  return formattedDate
}

export const isNumericInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const key = event.keyCode
  return (
    (key >= 48 && key <= 57) || // Allow number line
    (key >= 96 && key <= 105) // Allow number pad
  )
}

export const isModifierKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const key = event.keyCode
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) && (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  )
}

export const enforceFormat = (event: React.KeyboardEvent<HTMLInputElement>) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault()
  }
}

export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1])

  //Separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  //Write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)

  //Create a view into the buffer
  const ia = new Uint8Array(ab)

  //Set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  //Write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString })
}

export const ValidateEmail = (mail: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true
  }
  return false
}

export const validatePhoneNumber = (value: string) => {
  if (!value) return false
  const regexPhoneNumber = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
  return value.match(regexPhoneNumber)
}

export const DateDiff = {
  inDays: function (d1: Date, d2: Date) {
    const t2 = d2.getTime()
    const t1 = d1.getTime()

    return Math.floor((t2 - t1) / (24 * 3600 * 1000))
  },

  inWeeks: function (d1: Date, d2: Date) {
    const t2 = d2.getTime()
    const t1 = d1.getTime()

    return parseInt(((t2 - t1) / (24 * 3600 * 1000 * 7)).toString())
  },

  inMonths: function (d1: Date, d2: Date) {
    const d1Y = d1.getFullYear()
    const d2Y = d2.getFullYear()
    const d1M = d1.getMonth()
    const d2M = d2.getMonth()

    return d2M + 12 * d2Y - (d1M + 12 * d1Y)
  },

  inYears: function (d1: Date, d2: Date) {
    return d2.getFullYear() - d1.getFullYear()
  },
}

export const ProductType = (type?: string) => {
  switch (type) {
    case 'APARTMENT':
    case 'HOMESTAY':
    case 'VILLA':
      return 'HOUSE'
    case 'MOTOR_BIKE':
    case 'CAR_4':
    case 'CAR_16':
    case 'CAR_7':
    case 'SUPER_CAR':
      return 'VEHICLE'
    default:
      return null
  }
}
