import React from 'react'
import TitleWithRoute, { TitleWithRouteType } from '../../../components/TitleWithRoute'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import CardCarousel, { CarouselType } from 'components/CardCarousel'
import { Pagination } from 'swiper'
import { ApiParamsProps, GenericDataType, BDSNewsApiService } from 'services'
import { ItemType, NewsItem } from 'utils/types'
import ROUTES from 'utils/constants/routesConstant'
import { useHistory } from 'react-router-dom'

const BDSNews = () => {
  const { t } = useTranslation('home')
  const history = useHistory()

  const { data, isLoading } = BDSNewsApiService.useGet<GenericDataType<NewsItem>>({
    params: {
      page: 1,
      limit: 6,
    } as ApiParamsProps,
  })

  return (
    <Box className="mt-4">
      <TitleWithRoute buttonText={t('find_more_news')} href={`${ROUTES.NEWS}?page=1&limit=9`} title={t('bds_news')} type={TitleWithRouteType.BACKGROUND_BUTTON} />
      <Box className="mt-8">
        <Swiper
          modules={[Pagination]}
          spaceBetween={18}
          slidesPerView={3}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '"></span>'
            },
          }}
        >
          {data?.data &&
            data?.data?.result?.map((item, index) => (
              <SwiperSlide className="!h-[350px]" key={index} onClick={() => history.push({ pathname: `${ROUTES.NEWS}/${item._id}` })}>
                <CardCarousel type={CarouselType.NEW} title={item.title} starCount={5} description={item.title} index={index} />
              </SwiperSlide>
            ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default BDSNews
