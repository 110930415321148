import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

export default function SelectBox() {
  const [age, setAge] = React.useState('10')

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value)
  }

  return (
    <Select value={age} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }} size="small" className="text-center">
      <MenuItem value={10}>Taobao</MenuItem>
      <MenuItem value={20}>TMall</MenuItem>
      <MenuItem value={30}>1688</MenuItem>
    </Select>
  )
}
