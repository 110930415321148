import { Box, Divider, Tab, Tabs } from '@mui/material'
import React from 'react'
import FilterBackground from 'assets/images/filterBackground.png'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { AttachMoneyOutlined, DirectionsCarOutlined, HomeOutlined, VillaOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import FilterForRent from './FilterForRent'
import FilterForVehicle from './FilterForVehicle'
import { useRouteMatch } from 'react-router-dom'
import ROUTES from 'utils/constants/routesConstant'
import FilterForProject from './FilterForProject'
import { DefaultApiService, GenericDataType } from 'services'
import { useQueryParams } from 'hooks/useQueryParams'
import Stack from '@mui/material/Stack'
import SelectBox from 'components/ui/SelectBoxField'
import TextFieldIcon from 'components/ui/TextFieldIcon'

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#DF3E23',
            fontWeight: 'bold',
          },
          color: '#ADB3BC',
          textTransform: 'none',
          fontSize: '16px',
        },
      },
    },
  },
})

export enum TabsType {
  RENT = 0,
  FOR_SALE = 1,
  PROJECT = 2,
  VEHICLE = 3,
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export type OptionsFilter = {
  type_rental?: string[]
  house_type?: string[]
  vehicle_type?: string[]
  gender?: string[]
}

export type OptionsAddress = {
  code: string
  image: string
  lat: number
  long: number
  name: string
  name_with_type: string
  parent_code: string
  path_with_type: string
  _id: string
}

export type Option = {
  label: string
  value: string
}

export type ListAutocomplete = {
  name: string
  label: string
  options: Option[]
  placeholder: string
  isLoading: boolean
  defaultValue: string
}

const Filter = () => {
  const { t } = useTranslation('home')
  const [tab, setTabs] = React.useState<TabsType>(TabsType.RENT)
  const { path } = useRouteMatch()
  const { data, isLoading } = DefaultApiService.useGet<GenericDataType<OptionsFilter>>({
    url: '/master-data',
  })
  const currentParams = useQueryParams()

  const { data: dataAddress, isLoading: isLoadingAddress } = DefaultApiService.useGet<GenericDataType<OptionsAddress>>({
    url: '/cadastral/district/31',
  })

  const optionsTimeStay: { label: string; value: string }[] = React.useMemo(() => {
    const res = data?.data?.result?.[0]
    if (res?.type_rental) {
      return tab === TabsType.FOR_SALE
        ? res?.type_rental?.map((item) => ({ label: t(item?.toLowerCase()), value: item }))
        : res?.type_rental?.filter((item) => item !== 'FOREVER').map((item) => ({ label: t(item?.toLowerCase()), value: item }))
    }
    return []
  }, [data?.data?.result, t, tab])

  const optionsType: { label: string; value: string }[] = React.useMemo(() => {
    const res = data?.data?.result?.[0]
    if (res?.house_type) {
      return res?.house_type?.map((item) => ({ label: t(item?.toLowerCase()), value: item }))
    }
    return []
  }, [data?.data?.result, t])

  const optionsArea: { label: string; value: string }[] = React.useMemo(() => {
    if (dataAddress?.data?.result) {
      return dataAddress?.data?.result?.map((item) => ({ label: item?.name_with_type, value: item?.code }))
    }
    return []
  }, [dataAddress?.data?.result])

  const listAutocomplete: ListAutocomplete[] = React.useMemo(
    () => [
      {
        name: 'district',
        label: t('area'),
        options: optionsArea,
        placeholder: t('area_placeholder'),
        isLoading: isLoadingAddress,
        defaultValue: currentParams?.district,
      },
      {
        name: 'type',
        label: t('type'),
        options: optionsType,
        placeholder: t('type_placeholder'),
        isLoading: isLoading,
        defaultValue: currentParams?.type,
      },
      {
        name: 'type_rental',
        label: t('time_stay'),
        options: optionsTimeStay,
        placeholder: t('time_stay_placeholder'),
        isLoading: isLoading,
        defaultValue: currentParams?.['type_rental'],
      },
    ],
    [t, optionsArea, isLoadingAddress, currentParams, optionsType, isLoading, optionsTimeStay]
  )

  const listTabsContent = React.useMemo(
    () => [
      {
        component: <FilterForRent key={0} route={ROUTES.HOUSE} listAutocomplete={listAutocomplete} isLoading={isLoading} currentParams={currentParams} />,
      },
      {
        component: <FilterForRent key={1} currentParams={currentParams} route={ROUTES.HOUSE} listAutocomplete={listAutocomplete} isLoading={isLoading} />,
      },
      {
        component: <FilterForProject key={2} listAutocomplete={listAutocomplete.slice(0, 2)} isLoading={isLoading} currentParams={currentParams} />,
      },
      {
        component: <FilterForVehicle key={3} />,
      },
    ],
    [currentParams, isLoading, listAutocomplete]
  )

  const listTabs = React.useMemo(
    () => [
      {
        label: t('for_rent'),
        value: TabsType.RENT,
        icon: <HomeOutlined />,
      },
      {
        label: t('for_sale'),
        value: TabsType.FOR_SALE,
        icon: <AttachMoneyOutlined />,
      },
      {
        label: t('project'),
        value: TabsType.PROJECT,
        icon: <VillaOutlined />,
      },
      {
        label: t('vehicle_for_rent_drive_yourself'),
        value: TabsType.VEHICLE,
        icon: <DirectionsCarOutlined />,
      },
    ],
    [t]
  )

  const handleChange = (e: React.SyntheticEvent, value: number) => {
    setTabs(value)
  }

  React.useEffect(() => {
    if (path.includes(ROUTES.VEHICLE_SEARCH)) {
      setTabs(TabsType.VEHICLE)
    } else if (!path.includes(ROUTES.VEHICLE_SEARCH)) {
      if (currentParams && currentParams?.type_rental === 'FOREVER') {
        setTabs(TabsType.FOR_SALE)
      } else setTabs(TabsType.RENT)
    }
  }, [currentParams, path])

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="h-[600px] desktop:w-full flex items-center justify-center"
        sx={{
          backgroundImage: `url(${FilterBackground})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box className="cls-box-header text-white min-h-[236px] rounded-xl desktop:px-8 py-6 flex items-center flex-col desktop:max-w-[1248px] w-full flex-wrap">
          <h1>ĐĂNG KHOA BÌNH DƯƠNG LOGISTIC</h1>
          <h6>Chúng tôi mang đến cho bản giải pháp logistic chuyên nghiệp nhất</h6>

          <Box className="cls-box-search" sx={{ color: '#fff' }}>
            <Stack className="bg-white" direction="row" justifyContent="center" alignItems="center" spacing={0}>
              <SelectBox />
              <TextFieldIcon placeholder="Nhập sản phẩm tìm kiếm" />
            </Stack>
            <Stack className="bg-white" direction="row" justifyContent="center" alignItems="center" spacing={0}>
              <div className="cls-tracking">Tracking</div>
              <TextFieldIcon placeholder="Nhập mã vận đơn để kiểm tra" />
            </Stack>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default Filter
