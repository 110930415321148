import { Box } from '@mui/material'
import Partner from './components/Partner'
import ViewedPlaces from './components/ViewedPlaces'
import DiscoveryNearby from './components/DiscoveryNearby'
import LongTimePlace from './components/LongTimePlace'
import FavoritePlace from './components/FavoritePlace'
import ShortTimePlace from './components/ShortTimePlace'
import VehicleForRent from './components/VehicleForRent'
import FlashSaleInDay from './components/FlashSaleInDay'
import HouseTypes from './components/HouseTypes'
import BDSNews from './components/BDSNews'
import Filter from '../../components/Filter'
import { ApiParamsProps, FlashSaleComingApiService, GenericDataType } from '../../services'
import { ItemType } from 'utils/types'

type FlashSaleType = {
  result: {
    title: string
    icon: string
    video: string
    time_sale: string
    has_sale: boolean
    data: GenericDataType<ItemType>
  }[]
}

const HomePage = () => {
  const { data: dataFlashSale, isLoading: isLoadingFlashSale } = FlashSaleComingApiService.useGet<FlashSaleType>({
    params: { commodity_type: 'HOUSE', page: 1, limit: 5 } as ApiParamsProps,
  })

  return (
    <Box>
      <Filter />
      <Box className="max-w-[1440px] mx-auto px-14">
        <DiscoveryNearby />
        {/* House Hot */}
        <HouseTypes />
        <Box className="flex flex-col gap-20">
          {dataFlashSale?.data?.result.map((item, index) => (
            <FlashSaleInDay key={index} counter={item?.data?.total} data={item?.data?.result} isLoading={isLoadingFlashSale} title={item?.title} timeSale={item?.time_sale} video={item?.video} />
          ))}
          {/* House Hot Long Time*/}
          <LongTimePlace />
          {/* Vehicle For Rent*/}
          {/* House Favorite */}
          {/* <FavoritePlace /> */}
          {/* House Hot Short Time*/}
          <ShortTimePlace />

          {/* <VehicleForRent /> */}
          <ViewedPlaces />
          <BDSNews />
        </Box>
        <Partner />
      </Box>
    </Box>
  )
}

export default HomePage
