import { Box, CircularProgress, Pagination } from '@mui/material'
import CardItem from 'components/CardItem'
import CustomBreadcrumbs from 'components/CustomBreadcrumbs'
import Filter from 'components/Filter'
import { useQueryParams } from 'hooks/useQueryParams'
import qs from 'qs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ApiParamsProps, FlashSaleApiService, GenericDataType, HouseFavoriteApiService, HouseHotApiService, VehicleSearchApiService } from 'services'
import { CACHE_DATA, constantParams } from 'utils/constants'
import ROUTES from 'utils/constants/routesConstant'
import { ItemType } from 'utils/types'

const services = [
  { route: ROUTES.HOUSE_HOT, service: HouseHotApiService },
  { route: ROUTES.FLASH_SALE_IN_DAY, service: FlashSaleApiService },
  { route: ROUTES.HOUSE_FAVORITE, service: HouseFavoriteApiService },
  { route: ROUTES.VEHICLE_SEARCH, service: VehicleSearchApiService },
]

const CategoryPage = () => {
  const currentParams = useQueryParams()
  const { path } = useRouteMatch()
  const history = useHistory()
  const { t } = useTranslation('home')

  const currentService = services.find((x) => path.includes(x.route))?.service || HouseHotApiService

  const { data, isLoading } = currentService.useGet<GenericDataType<ItemType>>({
    params: { ...currentParams } as ApiParamsProps,
    options: {
      enabled: path !== ROUTES.VIEWED,
    },
  })

  const mappingData: ItemType[] = React.useMemo(() => {
    const localData = localStorage.getItem(CACHE_DATA)
    if (path !== ROUTES.VIEWED) {
      return data?.data?.result
    }
    if (localData) {
      const parsedData = JSON.parse(localData)
      if (parsedData && parsedData.length) {
        return parsedData.slice(
          (Number(currentParams?.page || constantParams.page) - 1) * Number(currentParams?.limit || constantParams.limit),
          Number(currentParams?.page || constantParams.page) * Number(currentParams?.limit || constantParams.limit)
        )
      }
      return []
    }
  }, [currentParams?.limit, currentParams?.page, data?.data?.result, path])

  const totalPage = React.useMemo(() => {
    if (data && path !== ROUTES.VIEWED) {
      return data?.data?.totalPage
    } else {
      const localData = localStorage.getItem(CACHE_DATA)
      if (localData) {
        const parsedData = JSON.parse(localData)
        return Math.ceil(parsedData.length / Number(currentParams?.limit || constantParams.limit))
      }
    }
  }, [currentParams?.limit, data, path])

  const handleChange = (_: any, page: number) => {
    const newParams = { ...currentParams, page, limit: Number(currentParams?.limit || constantParams.limit) }
    history.push(path + '?' + qs.stringify(newParams))
  }

  return (
    <Box>
      <Filter />
      <CustomBreadcrumbs />
      <Box className="pb-[136px] max-w-[1440px] mx-auto px-14 pt-14">
        {isLoading ? (
          <Box className="flex justify-center my-8">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="flex flex-wrap gap-5">
            {mappingData?.map((item, index) => (
              <CardItem
                itemData={item}
                key={index}
                type={item.type}
                name={item.name}
                bathCount={item.n_bathroom}
                bedCount={item.n_bedroom}
                updatedTime={item.updated_at}
                onClick={() => history.push({ pathname: `${ROUTES.HOUSE}/${item._id}` })}
              />
            ))}
          </Box>
        )}
        {!isLoading && !mappingData?.length && <Box className="text-center text-black-1 italic my-8">{t('no_product_exist')}</Box>}
        {!!mappingData?.length && (
          <Box className="flex justify-center mt-8">
            <Pagination variant="outlined" count={totalPage} shape="rounded" onChange={handleChange} disabled={isLoading} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CategoryPage
