const ROUTES = {
  HOME: '/',
  VEHICLE: '/vehicles',
  VEHICLE_SEARCH: '/vehicles/search',
  VEHICLE_DETAIL: '/vehicles/:id',
  HOUSE: '/houses',
  HOUSE_HOT: '/houses/hot',
  HOUSE_FAVORITE: '/houses/favorite',
  BDS_FOR_SELL: '/houses',
  BDS_FOR_RENT_DETAIL: '/houses/:id',
  BDS_FOR_SELL_DETAIL: '/houses/:id',
  FLASH_SALE_IN_DAY: '/flash-sales',
  FLASH_SALE_IN_DAY_COMMING: '/flash-sales/comming',
  PROFILE: '/profile',
  VIEWED: '/viewed',
  NOT_FOUND: '/not-found',
  NEWS: '/news',
  NEWS_DETAIL: '/news/:id',
}

export default ROUTES
