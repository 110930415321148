import React from 'react'
import CardItem from 'components/CardItem'
import { Box } from '@mui/material'
import TitleWithRoute, { TitleWithRouteType } from 'components/TitleWithRoute'
import { useTranslation } from 'react-i18next'
import { CACHE_DATA } from 'utils/constants'
import { useHistory } from 'react-router-dom'
import { ItemType } from 'utils/types'
import ROUTES from 'utils/constants/routesConstant'
import { convertPrice, onClickCard } from 'utils/functions'
import { GenericDataType, HouseApiService, VehicleApiService } from 'services'
import useAuth from 'hooks/useAuth'

const ViewedPlaces = () => {
  const { t } = useTranslation('home')
  const history = useHistory()
  const [data, setData] = React.useState<ItemType[]>([])
  const { token } = useAuth()
  const { data: dataHouse, isLoading: isLoadingHouse } = HouseApiService.useGet<GenericDataType<ItemType>>({
    url: '/revert-seen',
    params: {
      limit: 2,
      page: 1,
    },
    options: {
      enabled: !!token,
    },
  })

  const { data: dataVehicle, isLoading: isLoadingVehicle } = VehicleApiService.useGet<GenericDataType<ItemType>>({
    url: '/revert-seen',
    params: {
      limit: 3,
      page: 1,
    },
    options: {
      enabled: !!token,
    },
  })

  // const dataWithToken = React.useMemo(() => {
  //   const houseData = dataHouse?.data?.result
  //   const vehicleData = dataVehicle?.data?.result
  // }, [])

  React.useEffect(() => {
    if (!token) {
      const localData = localStorage.getItem(CACHE_DATA)
      if (localData) {
        const parsedData = JSON.parse(localData)
        if (parsedData && parsedData.length) {
          setData(parsedData.slice(0, 5).reverse())
        } else setData([])
      }
    }
  }, [dataHouse?.data?.result, dataVehicle?.data?.result, token])

  if (!data?.length) return null

  return (
    <Box>
      <TitleWithRoute buttonText={t('watch_all')} href={`${ROUTES.VIEWED}?page=1&limit=10`} title={t('viewed_places')} type={TitleWithRouteType.BACKGROUND_TITLE} />
      <Box className="flex flex-wrap gap-5">
        {data?.map((item, index) => (
          <CardItem
            itemData={item}
            key={index}
            type={item.type}
            name={item.name}
            bathCount={item.n_bathroom}
            bedCount={item.n_bedroom}
            updatedTime={item.updated_at}
            price={convertPrice(item?.sale_price || item?.price)}
            onClick={() => onClickCard({ history: history, type: item.type, item: item, id: item._id })}
          />
        ))}
      </Box>
      {!data.length && <Box className="text-center text-black-1 italic my-8">{t('no_product_exist')}</Box>}
    </Box>
  )
}

export default ViewedPlaces
