import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useParams, useRouteMatch } from 'react-router-dom'

import CustomBreadcrumbs from 'components/CustomBreadcrumbs'
import { ApiParamsProps, GenericDataType, HouseApiService, VehicleApiService } from 'services'
import ROUTES from 'utils/constants/routesConstant'
import { ProductType } from 'utils/functions'
import { ImageItem, ItemType } from 'utils/types'
import DetailInformation from './components/DetailInformation'
import ImageSlider from './components/ImageSlider'
import PostInformation from './components/PostInformation'
import ProductInformation from './components/ProductInformation'
import SimilarProducts from './components/SimilarProducts'
import ViewProducts from './components/ViewProducts'

const DetailPage = () => {
  const params = useParams<{ id: string }>()
  const { path } = useRouteMatch()
  const { t } = useTranslation('detail')

  const { data, isLoading } = HouseApiService.useGet<GenericDataType<ItemType>>({
    url: `/${params?.id}`,
    params: {} as ApiParamsProps,
    options: {
      enabled: path.includes(ROUTES.HOUSE),
    },
  })

  const { data: dataVehicle, isLoading: isLoadingVehicle } = VehicleApiService.useGet<GenericDataType<ItemType>>({
    url: `/${params?.id}`,
    params: {} as ApiParamsProps,
    options: {
      enabled: path.includes(ROUTES.VEHICLE),
    },
  })

  const orgData = React.useMemo(() => {
    if (path.includes(ROUTES.VEHICLE)) return dataVehicle?.data?.result[0]
    return data?.data?.result[0]
  }, [data?.data?.result, dataVehicle?.data?.result, path])

  const isNullData = React.useMemo(() => {
    if (path.includes(ROUTES.HOUSE)) {
      return (data && !data?.data?.result.length) || data?.data?.result[0] === null
    } else return (dataVehicle && !dataVehicle?.data?.result.length) || dataVehicle?.data?.result[0] === null
  }, [data, dataVehicle, path])

  if (isNullData) {
    return <Redirect to={ROUTES.NOT_FOUND} exact={true} />
  }

  if (isLoading || isLoadingVehicle) {
    return (
      <Box className="min-h-[500px] flex items-center justify-center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <CustomBreadcrumbs />
      <Box className="max-w-[1440px] mx-auto px-14 py-8 flex flex-wrap gap-6">
        <ImageSlider imgs={orgData?.images || ([] as ImageItem[])} />
        <ProductInformation
          projectName="Hilton Hải Phòng"
          isPopover={!!orgData?.type && ProductType(orgData?.type) === 'HOUSE'}
          popoverContent={t('difference_price') || ''}
          code={37341637}
          data={orgData}
        />
        <PostInformation data={orgData} />
      </Box>
      <Box className="bg-background-2">
        <Box className="flex flex-col py-4 gap-4 desktop:max-w-[1440px] mx-auto justify-center desktop:flex-row">
          <Box className="desktop:max-w-[994px] w-full gap-4 flex flex-col">
            <DetailInformation data={orgData} />
            <ViewProducts />
          </Box>
          <Box>
            <SimilarProducts typeRental={orgData?.type_rental} type={orgData?.type} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default DetailPage
