import * as React from 'react'
import Box from '@mui/material/Box'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'

import AccountCircle from '@mui/icons-material/AccountCircle'
import SearchIcon from '@mui/icons-material/Search'
import OutlinedInput from '@mui/material/OutlinedInput'
import IconButton from '@mui/material/IconButton'

import TextField, { TextFieldProps } from '@mui/material/TextField'

type TextFieldIconProp = TextFieldProps & {
  name?: string
}

const TextFieldIcon: React.FC<TextFieldIconProp> = ({ name, ...rest }) => {
  return (
    <TextField
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  )
}

export default TextFieldIcon
