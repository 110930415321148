import React from 'react'
import TitleWithRoute, { TitleWithRouteType } from 'components/TitleWithRoute'
import { Box, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import CardCarousel, { CarouselType } from 'components/CardCarousel'
import { Pagination } from 'swiper'
import { ItemType } from 'utils/types'
import { useHistory } from 'react-router-dom'
import ROUTES from 'utils/constants/routesConstant'
import { ApiParamsProps, GenericDataType, HouseHotApiService } from 'services'

type ItemCarousel = {
  [key: string]: string | boolean | number | undefined
  name: string
  description: string
  avg_star: number
  _id: string
}

const HouseTypes = () => {
  const { data, isLoading } = HouseHotApiService.useGet<GenericDataType<ItemType>>({
    params: {
      page: 1,
      limit: 9,
    } as ApiParamsProps,
  })
  const { t } = useTranslation('home')
  const history = useHistory()

  const filteredData = React.useMemo(() => {
    const keys = ['name', 'description', 'avg_star', '_id']
    if (data?.data?.result && data?.data?.result.length) {
      return data?.data?.result.map((item) => {
        const obj: ItemCarousel = {} as ItemCarousel
        keys.forEach((k) => (obj[k] = item?.[k]))
        return obj
      })
    }
  }, [data])

  return (
    <Box className="mt-4">
      <TitleWithRoute buttonText={t('go_discover_now')} href={`${ROUTES.HOUSE_HOT}?page=1&limit=10`} title={t('discovery_house_type_title')} type={TitleWithRouteType.BACKGROUND_BUTTON} />
      <Box className="text-content-16 text-icon text-center">{t('discovery_house_type_description')}</Box>
      {isLoading ? (
        <Box className="flex justify-center my-8">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="mt-8">
          <Swiper
            modules={[Pagination]}
            spaceBetween={18}
            slidesPerView={3}
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                return '<span class="' + className + '"></span>'
              },
            }}
          >
            {filteredData?.map((item, index) => (
              <SwiperSlide className="!h-[350px]" key={index} onClick={() => history.push({ pathname: `${ROUTES.HOUSE}/${item._id}` })}>
                <CardCarousel type={CarouselType.HOUSE} title={item.name} starCount={item.avg_star} description={item.description} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}
      {!isLoading && !data?.data?.total && <Box className="text-center text-black-1 italic my-8">{t('no_product_exist')}</Box>}
    </Box>
  )
}

export default HouseTypes
