import React from 'react'
import CardItem from 'components/CardItem'
import { Box, CircularProgress } from '@mui/material'
import TitleWithRoute, { TitleWithRouteType } from 'components/TitleWithRoute'
import { useTranslation } from 'react-i18next'
import ROUTES from 'utils/constants/routesConstant'
import { ItemType } from 'utils/types'
import { useHistory } from 'react-router-dom'
import { ApiParamsProps, GenericDataType, HouseHotApiService } from 'services'
import { convertPrice, onClickCard } from 'utils/functions'

const LongTimePlace = () => {
  const { data, isLoading } = HouseHotApiService.useGet<GenericDataType<ItemType>>({
    params: {
      page: 1,
      limit: 5,
      type_rental: 'MONTH',
    } as ApiParamsProps,
  })

  const { t } = useTranslation('home')
  const history = useHistory()

  const filteredData = React.useMemo(() => {
    if (data?.data?.result && data?.data?.result?.length) {
      return data?.data?.result
    } else return []
  }, [data])

  return (
    <Box>
      <TitleWithRoute
        buttonText={t('watch_all')}
        href={`${ROUTES.HOUSE_HOT}?page=1&limit=10&type_rental=MONTH`}
        title={
          <>
            <Box>{t('long_time_place_title')}</Box>
          </>
        }
        type={TitleWithRouteType.BACKGROUND_TITLE}
      />
      {isLoading ? (
        <Box className="flex justify-center my-8">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="flex flex-wrap gap-5">
          {filteredData.map((item, index) => (
            <CardItem
              itemData={item}
              key={index}
              type={item.type}
              name={item.name}
              bathCount={item.n_bathroom}
              bedCount={item.n_bedroom}
              updatedTime={item.updated_at}
              price={convertPrice(item?.sale_price || item?.price)}
              onClick={() => onClickCard({ history: history, type: item.type, item: item, id: item._id })}
            />
          ))}
        </Box>
      )}
      {!isLoading && !data?.data?.total && <Box className="text-center text-black-1 italic my-8">{t('no_product_exist')}</Box>}
    </Box>
  )
}

export default LongTimePlace
