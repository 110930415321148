import React from 'react'

export interface LoginModalStatus {
  isLoggedIn?: boolean
  name?: string
  avatar?: string
  phone?: string
  otp_expire?: number
  password?: string
  username?: string
  email?: string
}

export type Actions = {
  type: ACTION_TYPE
  payload?: LoginModalStatus
}

export enum ACTION_TYPE {
  INIT,
  REDUCERS,
}

export const loginModalContext = React.createContext<LoginModalStatus | null>(null)
export const loginModalDispatchContext = React.createContext<React.Dispatch<Actions>>(() => {})

export function useLoginModalContext() {
  return React.useContext(loginModalContext)
}

export function useLoginModalDispatch() {
  return React.useContext(loginModalDispatchContext)
}
