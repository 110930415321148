import { MenuOutlined, NotificationsOutlined, PersonOutlineOutlined, ShoppingCartOutlined } from '@mui/icons-material'
import { Avatar, Box, IconButton } from '@mui/material'
import logoImage from 'assets/images/logo.png'
import { ACTION_TYPE, useLoginModalContext, useLoginModalDispatch } from 'context'
import useAuth from 'hooks/useAuth'
import { LoginResponse } from 'layouts/components/LoginModal/StepSignIn'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { AuthApiService, GenericDataType } from 'services'
import ROUTES from 'utils/constants/routesConstant'
import { generateMediaLink } from 'utils/functions'
import Sidebar from '../Sidebar'
import TopWidget from './components/TopWidget'

const Navbar: React.FC = () => {
  const { t } = useTranslation('navbar')
  const [state, setState] = React.useState<boolean>(false)
  const { token } = useAuth()
  const { data } = AuthApiService.useGet<GenericDataType<LoginResponse>>({
    url: '/me',
    options: { enabled: !!token },
  })
  const history = useHistory()
  const dispatch = useLoginModalDispatch()
  const loginContext = useLoginModalContext()

  const authProfile = React.useMemo(() => {
    if (!data) {
      return { avatar: loginContext?.avatar && generateMediaLink(loginContext?.avatar), name: loginContext?.name }
    }
    return {
      avatar: data?.data?.result[0].avatar && generateMediaLink(data?.data?.result[0].avatar),
      name: data?.data?.result[0].name,
    }
  }, [data, loginContext?.avatar, loginContext?.name])

  const handleSignIn = React.useCallback(() => {
    dispatch({ type: ACTION_TYPE.INIT, payload: { isLoggedIn: true } })
  }, [dispatch])

  const handleToggle = React.useCallback(() => {
    setState(true)
  }, [])

  const listButton = React.useMemo(() => {
    return [
      {
        icon: <NotificationsOutlined className="text-icon" />,
        text: <Box className="!text-content-14 text-black-1">{t('notification')}</Box>,
        href: '/',
      },
      {
        icon: <ShoppingCartOutlined className="text-icon" />,
        text: <Box className="!text-content-14 text-black-1">{t('cart')}</Box>,
        href: '/',
      },
    ]
  }, [t])

  React.useEffect(() => {
    if (data?.data?.result[0]) {
      dispatch({
        type: ACTION_TYPE.INIT,
        payload: {
          phone: data?.data?.result[0]?.phone,
          name: data?.data?.result[0]?.name,
          username: data?.data?.result[0]?.username,
          email: data?.data?.result[0]?.email,
          avatar: data?.data?.result[0]?.avatar,
        },
      })
    }
  }, [data?.data?.result, dispatch])

  return (
    <Box>
      <TopWidget />
      <Box className="bg-white h-[72px] flex items-center justify-around" sx={{ filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12))' }}>
        <Link to="/" style={{ maxHeight: '100%', display: 'flex' }}>
          <img src="/logo.png" alt="Thuê nhà đăng khoa" style={{ maxHeight: '100%' }} />
        </Link>
        <Box className="flex items-center gap-10">
          {listButton.map((item, index) => (
            <Link to={item?.href || '/xxx'} key={index} className="flex items-center cursor-pointer gap-1">
              {item.icon}
              <Box>{item.text}</Box>
            </Link>
          ))}
          <Box
            component={'a'}
            className="!text-content-14 text-brand-1 font-bold cursor-pointer flex items-center gap-2"
            onClick={authProfile?.name ? () => history.push(ROUTES.PROFILE) : () => handleSignIn()}
          >
            {!!token ? <Avatar src={authProfile.avatar} /> : <PersonOutlineOutlined className="text-icon" />}
            <Box>{authProfile?.name ? authProfile?.name : t('sign_up')}</Box>
          </Box>
          <IconButton className="flex items-center cursor-pointer gap-1" onClick={() => handleToggle()}>
            <MenuOutlined className=" text-black-base" />
          </IconButton>
        </Box>
      </Box>
      <Sidebar open={state} onClose={() => setState(false)} />
    </Box>
  )
}

export default React.memo(Navbar, () => true)
