import { AccessTime, RunningWithErrors, WidgetsOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getDateString } from 'utils/functions'
import { ItemType } from 'utils/types'

export enum NewsType {
  BDS_SELL = 'bds_sell',
  BDS_RENT = 'bds_rent',
}

type Props = {
  data?: ItemType
}

const DateInformation: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation('detail')

  const listData = React.useMemo(
    () => [
      { icon: <AccessTime />, label: t('start_date'), value: getDateString(data?.created_at) },
      { icon: <RunningWithErrors />, label: t('end_date'), value: getDateString(data?.expired_at) || 'N/A' },
      {
        icon: <WidgetsOutlined />,
        label: t('news_type'),
        value: t(data?.type_rental === 'FOREVER' ? NewsType.BDS_SELL : NewsType.BDS_RENT || ''),
      },
    ],
    [t, data?.created_at, data?.expired_at, data?.type_rental]
  )
  return (
    <Box>
      <Box className="text-content-14 font-bold text-brand-1 mb-2">{t('post_information')}</Box>
      <Box className="flex flex-col gap-1">
        {listData.map((item, index) => (
          <Box key={index} className="flex justify-between">
            <Box className="flex items-center gap-2">
              <Box className="!text-brand-1">{item.icon}</Box>
              <Box className="text-content-14 text-grey-2">{item.label}</Box>
            </Box>
            <Box className="text-[15px] text-black-1 font-[500]">{item.value}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default DateInformation
