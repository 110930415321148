import Footer from 'layouts/Footer'
import Navbar from 'layouts/Navbar'
import LoginModal from 'layouts/components/LoginModal'
import NewsCategoryPage from 'pages/NewsCategoryPage'
import NewsDetail from 'pages/NewsCategoryPage/NewsDetail'
import React, { ReactElement } from 'react'
import { Redirect, Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import CategoryPage from '../pages/CategoryPage'
import DetailPage from '../pages/DetailPage'
import HomePage from '../pages/HomePage'
import NotFoundPage from '../pages/NotFoundPage'
import ROUTES from '../utils/constants/routesConstant'

type Routers = {
  path: string | string[]
  element: ReactElement
  exact?: boolean
  isAuth?: boolean
}

export function ScrollToTop() {
  const { pathname } = useLocation()

  React.useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return null
}

const Routes = () => {
  const { token } = useAuth()

  const router: Routers[] = [
    {
      path: ROUTES.HOME,
      element: <HomePage />,
      exact: true,
    },
    {
      path: [
        ROUTES.HOUSE,
        ROUTES.BDS_FOR_SELL,
        ROUTES.VEHICLE,
        ROUTES.VEHICLE_SEARCH,
        ROUTES.HOUSE_HOT,
        ROUTES.FLASH_SALE_IN_DAY,
        ROUTES.FLASH_SALE_IN_DAY_COMMING,
        ROUTES.HOUSE_FAVORITE,
        ROUTES.VIEWED,
      ],
      element: <CategoryPage />,
      exact: true,
    },
    {
      path: [ROUTES.BDS_FOR_RENT_DETAIL, ROUTES.BDS_FOR_SELL_DETAIL, ROUTES.VEHICLE_DETAIL],
      element: <DetailPage />,
      exact: true,
    },
    {
      path: ROUTES.NOT_FOUND,
      element: <NotFoundPage />,
    },
    {
      path: ROUTES.NEWS,
      element: <NewsCategoryPage />,
      exact: true,
    },
    {
      path: ROUTES.NEWS_DETAIL,
      element: <NewsDetail />,
    },
  ]

  const mappingRoute = (router: Routers[]) => {
    if (!router) return
    return (
      <Switch>
        {router.map((item, index) => {
          if (!!item.isAuth && !token) {
            return <Redirect to="/" key={index} />
          }
          return (
            <Route exact={item.exact} path={item.path} key={index}>
              {item.element}
            </Route>
          )
        })}
      </Switch>
    )
  }

  return (
    <Router>
      <Navbar />
      <LoginModal />
      {mappingRoute(router)}
      <Footer />
      <ScrollToTop />
    </Router>
  )
}

export default Routes
