import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import CardItem from 'components/CardItem'
import { useTranslation } from 'react-i18next'
import { ApiParamsProps, GenericDataType, HouseHotApiService, VehicleApiService, VehicleSearchApiService } from 'services'
import { ItemType } from 'utils/types'
import { useHistory, useRouteMatch } from 'react-router-dom'
import ROUTES from 'utils/constants/routesConstant'
import { convertPrice, onClickCard } from 'utils/functions'

type Props = {
  typeRental?: string
  type?: string
}

const SimilarProducts: React.FC<Props> = ({ typeRental, type }) => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const { t } = useTranslation('detail')
  const { data, isLoading } = HouseHotApiService.useGet<GenericDataType<ItemType>>({
    params: { page: 1, limit: 4, type_rental: typeRental } as ApiParamsProps,
    options: {
      enabled: path.includes(ROUTES.HOUSE),
    },
  })

  const { data: dataVehicle, isLoading: isLoadingVehicle } = VehicleSearchApiService.useGet<GenericDataType<ItemType>>({
    params: { page: 1, limit: 4, type: type } as ApiParamsProps,
    options: {
      enabled: path.includes(ROUTES.VEHICLE),
    },
  })

  const filteredData = React.useMemo(() => {
    if (path.includes(ROUTES.VEHICLE)) return dataVehicle?.data?.result
    return data?.data?.result
  }, [data?.data?.result, dataVehicle?.data?.result, path])

  return (
    <Box className="bg-white rounded-xl p-8 desktop:max-w-[310px]">
      <Box className="text-content-18 font-bold text-brand-1 text-center mb-2">{t('similar_product')}</Box>
      {isLoading || isLoadingVehicle ? (
        <Box className="flex justify-center my-8">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="flex desktop:flex-col gap-2 overflow-auto">
          {filteredData?.map((item, index) => (
            <CardItem
              itemData={item}
              key={index}
              type={item.type}
              name={item.name}
              bathCount={item.n_bathroom}
              bedCount={item.n_bedroom}
              price={convertPrice(item?.sale_price || item?.price)}
              updatedTime={item.updated_at}
              onClick={() => onClickCard({ history: history, type: item.type, item: item, id: item._id })}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

export default SimilarProducts
