import { AccountCircle, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { Box, Button, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import { AxiosError } from 'axios'
import { ACTION_TYPE, useLoginModalDispatch } from 'context'
import useAuth from 'hooks/useAuth'
import jwt_decode from 'jwt-decode'
import { useSnackbar } from 'notistack'
import { ErrorType } from 'pages/DetailPage/components/PostInformation/BookingForm'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AuthApiService, GenericDataType } from 'services'
import Cookies from 'universal-cookie'
import { JWT_AUTHORIZATION } from 'utils/constants'
import { LoginProps, StepLogin } from '.'

export interface LoginResponse {
  avatar?: string
  accessToken: string
  name?: string
  phone?: string
  refreshToken: string
  role_name?: string
  email?: string
  username?: string
  _id?: string
}

export interface MyToken {
  exp: number
}

const StepSignIn: React.FC<LoginProps> = ({ onChangeStep }) => {
  const { t } = useTranslation('login')
  const [showPassword, setShowPassword] = React.useState(false)
  const { data, isLoading, mutate, error } = AuthApiService.usePost<GenericDataType<LoginResponse>>({ url: '/login' })
  const dispatch = useLoginModalDispatch()
  const { setToken } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const cookies = React.useMemo(() => {
    return new Cookies()
  }, [])

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formProps = Object.fromEntries(formData)
    mutate({
      data: formProps,
    })
  }

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(t('login_success'), { variant: 'success' })
      const accessToken = data.data.result[0].accessToken
      const refreshToken = data.data.result[0].refreshToken
      dispatch({
        type: ACTION_TYPE.INIT,
        payload: {
          avatar: data?.data?.result[0].avatar,
          name: data?.data?.result[0].name,
          phone: data?.data?.result[0].phone,
          email: data?.data?.result[0].email,
        },
      })
      const decoded = jwt_decode<MyToken>(refreshToken)
      setToken(accessToken)
      cookies.set(JWT_AUTHORIZATION, refreshToken, { expires: new Date(decoded.exp * 1000) })
      onChangeStep?.(StepLogin.DONE_SIGN_IN)
    }
  }, [cookies, data, dispatch, enqueueSnackbar, onChangeStep, setToken, t])

  React.useEffect(() => {
    if (error) {
      const errorHandle = error as AxiosError<ErrorType>
      enqueueSnackbar(errorHandle.response?.data.data.message, { variant: 'error' })
    }
  }, [enqueueSnackbar, error, t])

  return (
    <Box component={'form'} className="flex flex-col w-full tablet:p-10 p-5" onSubmit={handleSubmit}>
      <Box className="text-center">
        <AccountCircle className="text-grey-1 !text-[90px]" />
        <Box className="text-black-1 text-content-title font-bold mb-4">{t('login')}</Box>
      </Box>
      <Box className="flex flex-col w-full gap-3 mb-4">
        <InputLabel className="text-content-14 !font-semibold !text-black-base" htmlFor="username">
          {t('phone_number')}
        </InputLabel>
        <TextField name="username" variant="outlined" required />
        <InputLabel className="text-content-14 !font-semibold !text-black-base" htmlFor="password">
          {t('password')}
        </InputLabel>
        <OutlinedInput
          name="password"
          type={showPassword ? 'text' : 'password'}
          required
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Box className="flex justify-end">
          <Box component={'a'} onClick={() => onChangeStep?.(StepLogin.FORGOT_PASSWORD)} className="cursor-pointer text-content-12 w-max hover:text-grey-1">
            {t('forgot_password')}
          </Box>
        </Box>
      </Box>
      <Button type="submit" className="!bg-brand-1 !text-white !normal-case !text-content-18 !font-[500] w-[200px] !mx-auto disabled:!bg-grey-1" disabled={isLoading}>
        {t('login')}
      </Button>
      <Box className="text-content-14 text-center mt-2">
        {t('not_have_account')}
        <Box component={'a'} onClick={() => onChangeStep?.(StepLogin.FORM_SIGN_UP)} className="cursor-pointer w-max hover:text-brand-2 text-brand-1 font-bold">
          {t('register')}
        </Box>
      </Box>
    </Box>
  )
}

export default StepSignIn
