import { Close } from '@mui/icons-material'
import { Box, IconButton, Modal } from '@mui/material'
import { ACTION_TYPE, useLoginModalContext, useLoginModalDispatch } from 'context'
import React from 'react'
import { useTranslation } from 'react-i18next'
import StepCreatePassword from './StepCreatePassword'
import StepDone from './StepDone'
import StepOTP from './StepOTP'
import StepSignIn from './StepSignIn'
import StepSignUp from './StepSignUp'
import StepUpdateProfile from './StepUpdateProfile'

export enum StepLogin {
  FORM_SIGN_IN,
  FORM_SIGN_UP,
  OTP,
  FORGOT_PASSWORD,
  DONE_SIGN_IN,
  DONE_OTP,
  CREATE_PASSWORD,
  UPDATE_PROFILE,
}

export type LoginProps = {
  onChangeStep?: (value: StepLogin) => void
  onClose?: () => void
  title?: string
}

const LoginModal = () => {
  const [step, setStep] = React.useState<StepLogin>(StepLogin.FORM_SIGN_IN)
  const profileContext = useLoginModalContext()
  const dispatch = useLoginModalDispatch()
  const { t } = useTranslation('login')
  const onClose = () => {
    dispatch({ type: ACTION_TYPE.INIT, payload: { isLoggedIn: false } })
    setStep(StepLogin.FORM_SIGN_IN)
  }

  const renderStep = () => {
    switch (step) {
      case StepLogin.FORM_SIGN_IN:
        return <StepSignIn onChangeStep={setStep} />
      case StepLogin.FORM_SIGN_UP:
        return <StepSignUp onChangeStep={setStep} />
      case StepLogin.OTP:
        return <StepOTP onChangeStep={setStep} />
      case StepLogin.DONE_SIGN_IN:
        return <StepDone onClose={onClose} title={t('login_success') || ''} />
      case StepLogin.DONE_OTP:
        return <StepDone onClose={() => setStep(StepLogin.CREATE_PASSWORD)} title={t('input_otp_success') || ''} />
      case StepLogin.CREATE_PASSWORD:
        return <StepCreatePassword onChangeStep={setStep} />
      case StepLogin.UPDATE_PROFILE:
        return <StepUpdateProfile onClose={onClose} />
      default:
        return <></>
    }
  }

  const disabledClose = [StepLogin.DONE_SIGN_IN, StepLogin.DONE_OTP, StepLogin.CREATE_PASSWORD, StepLogin.UPDATE_PROFILE]

  return (
    <Modal open={!!profileContext?.isLoggedIn} onClose={onClose}>
      <Box
        className="
              relative
              top-[50%] 
              left-[50%] 
              max-w-[320px] 
              tablet:max-w-[500px] 
              translate-x-[-50%] 
              translate-y-[-50%] 
              w-full
              flex
              gap-4 
              bg-white 
              outline-none
              rounded-2xl
              "
      >
        {!disabledClose.includes(step) && (
          <IconButton className="!absolute top-2 right-2 !bg-white" size="small" onClick={onClose}>
            <Close />
          </IconButton>
        )}
        {renderStep()}
      </Box>
    </Modal>
  )
}

export default LoginModal
