import { Box, ButtonBase, styled } from '@mui/material'
import newsBgTopRight from 'assets/images/bgtopright.png'
import React from 'react'

type Props = {
  image?: string
  title?: string
  onClick?: () => void
}

const StyledCard = styled(ButtonBase)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
})

const CardNews: React.FC<Props> = ({ title, image, onClick }) => {
  return (
    <StyledCard onClick={onClick} className="!w-[290px] !rounded-xl !h-[367px] !p-3 cursor-pointer !text-left relative !bg-white " sx={{ boxShadow: '0px 0px 12px rgba(15, 61, 145, 0.12)' }}>
      <Box className="flex flex-col">
        <Box component={'img'} src={image || newsBgTopRight} className="pointer-events-none !w-[269px] !h-[166px] object-cover !rounded-xl" />
      </Box>
      <Box
        className="text-black-1 p-3 w-full text-[20px] break-words"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 4,
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
        }}
        title={title}
      >
        {title}
      </Box>
    </StyledCard>
  )
}

export default CardNews
