import { Autocomplete, Avatar, Box, Button, FormControlLabel, InputLabel, RadioGroup, TextField } from '@mui/material'
import { ACTION_TYPE, useLoginModalContext, useLoginModalDispatch } from 'context'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import { LoginProps } from '.'
import { AuthApiService, DefaultApiService, GenericDataType, RolesApiService, UserApiService } from 'services'
import { LoginResponse, MyToken } from './StepSignIn'
import useAuth from 'hooks/useAuth'
import { Cookies } from 'react-cookie'
import jwt_decode from 'jwt-decode'
import { JWT_AUTHORIZATION } from 'utils/constants'
import RadioControl from 'components/RadioControl'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ValidateEmail, dataURItoBlob, getDateString } from 'utils/functions'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import SnackbarAction from 'components/SnackbarAction'

type Role = { name: string; _id: string }
type Options = { label: string; value: string }

type UploadResponse = {
  hasFile: boolean
  images: string[]
  videos: string[]
}

const StepUpdateProfile: React.FC<LoginProps> = ({ onClose }) => {
  const { t } = useTranslation('login')
  const [images, setImages] = React.useState<ImageListType>([])
  const [startDate, setStartDate] = React.useState<Date | null>(new Date())
  const dispatch = useLoginModalDispatch()
  const { setToken } = useAuth()

  const cookies = React.useMemo(() => {
    return new Cookies()
  }, [])

  const { data } = RolesApiService.useGet<GenericDataType<Role>>({})
  const {
    data: dataUpload,
    mutate: mutateUpload,
    isLoading,
    isSuccess,
  } = DefaultApiService.usePost<GenericDataType<UploadResponse>>({
    url: '/uploads',
  })

  const { data: dataUser, mutate: mutateUser, error: errorUser } = UserApiService.usePost<GenericDataType<any>>({ url: '' })
  const { data: dataLogin, mutate: mutateLogin } = AuthApiService.usePost<GenericDataType<LoginResponse>>({
    url: '/login',
  })

  const { data: dataUpdated, mutate: mutateUpdateImage } = UserApiService.usePatch<GenericDataType<LoginResponse>>({
    url: `/${dataLogin?.data?.result[0]?._id}/profile`,
  })

  const onChange = (imageList: ImageListType) => {
    setImages(imageList)
  }

  const loginContext = useLoginModalContext()

  const options: Options[] = React.useMemo(() => {
    if (data && data?.data?.result) {
      return data?.data?.result?.map((item) => ({ label: t(item.name.toLowerCase()) || '', value: item._id }))
    }
    return []
  }, [data, t])
  const [value, setValue] = React.useState<Options | null>(options[0])
  const { enqueueSnackbar } = useSnackbar()
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formProps = Object.fromEntries(formData)
    dispatch({
      type: ACTION_TYPE.INIT,
      payload: { username: formProps?.username?.toString() },
    })
    const birthday = !!startDate && getDateString(startDate)
    if (!ValidateEmail(formProps?.email?.toString())) {
      enqueueSnackbar(t('email_invalid', { action: SnackbarAction, variant: 'error' }))
      return
    }
    mutateUser({
      data: {
        phone: loginContext?.phone,
        username: formProps?.username?.toString(),
        password: loginContext?.password,
        name: formProps?.name?.toString(),
        role_id: value?.value?.toString(),
        birthday: birthday,
        email: formProps?.email?.toString(),
      },
    })
  }

  const errorHandle = React.useMemo(() => {
    return errorUser as AxiosError
  }, [errorUser])

  React.useEffect(() => {
    if (dataUser) {
      mutateLogin({
        data: {
          username: loginContext?.username,
          password: loginContext?.password,
        },
      })
    }
  }, [dataUser, loginContext?.password, loginContext?.username, mutateLogin])

  React.useEffect(() => {
    if (dataLogin?.data?.result[0]) {
      const accessToken = dataLogin.data.result[0].accessToken
      const refreshToken = dataLogin.data.result[0].refreshToken
      dispatch({
        type: ACTION_TYPE.INIT,
        payload: {
          name: dataLogin?.data?.result[0].name,
          email: dataLogin?.data?.result[0].email,
        },
      })
      const decoded = jwt_decode<MyToken>(refreshToken)
      setToken(accessToken)
      cookies.set(JWT_AUTHORIZATION, refreshToken, { expires: new Date(decoded.exp * 1000) })
    }
  }, [cookies, dataUpload, dataLogin, dispatch, images, mutateUpload, setToken])

  React.useEffect(() => {
    if (dataLogin?.data?.result[0] && !dataUpload?.data?.result[0]) {
      const image = images[0]?.data_url
      if (image) {
        const formData = new FormData()
        formData.append('images', dataURItoBlob(images[0]?.data_url), images[0]?.file?.name)
        dispatch({
          type: ACTION_TYPE.INIT,
          payload: {
            avatar: image,
          },
        })
        mutateUpload({
          data: formData,
        })
      }
    }
  }, [dataLogin, dataUpload, dispatch, images, mutateUpload])

  React.useEffect(() => {
    if (dataUpload?.data?.result[0]) {
      const img = dataUpload?.data?.result[0]?.images[0]
      if (img) {
        mutateUpdateImage({
          data: { avatar: img },
        })
      }
    }
  }, [dataUpload, mutateUpdateImage])

  React.useEffect(() => {
    if (dataUpdated?.data?.result[0]) {
      const img = dataUpdated?.data?.result[0]?.avatar
      if (img) {
        dispatch({
          type: ACTION_TYPE.INIT,
          payload: {
            avatar: img,
          },
        })
      }
      onClose?.()
    }
  }, [dataUpdated, dispatch, onClose])

  return (
    <Box className="w-full tablet:p-8 p-5">
      <Box className="text-content-18 font-semibold !text-[#162A4C] mb-2">{t('update_information')}</Box>
      <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
        {({ imageList, onImageUpload }) => (
          <Box className="flex items-center">
            {imageList?.length ? (
              imageList.map((image, index) => <Box component={'img'} src={image['data_url']} alt="" width="80px" height="80px" className="object-contain rounded-full" key={index} />)
            ) : (
              <Avatar className="!w-[80px] !h-[80px]" />
            )}
            <Button
              onClick={() => {
                setImages([])
                onImageUpload()
              }}
              variant="outlined"
              className="!border-grey-1 !normal-case !text-content-14 !text-[#162A4C] !ml-3"
            >
              Chọn ảnh
            </Button>
          </Box>
        )}
      </ImageUploading>
      <Box component={'form'} onSubmit={handleSubmit} className="flex flex-col gap-3 mt-2">
        <Box className="w-full">
          <InputLabel className="text-content-14 !text-[#162A4C]" htmlFor="username">
            {t('username')}
          </InputLabel>
          <TextField
            name="username"
            size="small"
            required
            className="w-full"
            sx={{
              '& .MuiInputBase-root': {
                borderRadius: '8px',
              },
            }}
          />
        </Box>
        <Box className="w-full">
          <InputLabel className="text-content-14 !text-[#162A4C]" htmlFor="name">
            {t('name')}
          </InputLabel>
          <TextField
            name="name"
            size="small"
            required
            className="w-full"
            sx={{
              '& .MuiInputBase-root': {
                borderRadius: '8px',
              },
            }}
          />
        </Box>
        <Box className="w-full">
          <InputLabel className="text-content-14 !text-[#162A4C]" htmlFor="email">
            {t('email')}
          </InputLabel>
          <TextField
            name="email"
            size="small"
            required
            className="w-full"
            sx={{
              '& .MuiInputBase-root': {
                borderRadius: '8px',
              },
            }}
          />
        </Box>
        <Box className="w-full">
          <InputLabel className="text-content-14 !text-[#162A4C] " htmlFor="phone">
            {t('phone_number')}
          </InputLabel>
          <TextField
            name="phone"
            disabled
            value={loginContext?.phone}
            size="small"
            required
            className="w-full"
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: '#DADADA',
                borderRadius: '8px',
              },
              '& .Mui-disabled': {
                WebkitTextFillColor: 'black',
              },
            }}
          />
        </Box>
        <Box className="w-full">
          <InputLabel className="text-content-14" htmlFor="role_id">
            {t('role_id')}
          </InputLabel>
          <Autocomplete
            disablePortal
            options={options}
            value={value}
            size="small"
            sx={{
              '& .MuiInputBase-root': {
                padding: '5px 14px',
              },
              'input::placeholder': { fontStyle: 'italic', fontSize: '14px' },
            }}
            onChange={(e, v) => setValue(v)}
            renderInput={({ inputProps, ...rest }) => <TextField {...rest} required InputLabelProps={{ required: true }} inputProps={{ ...inputProps, readOnly: true, required: !value?.value }} />}
          />
        </Box>
        <Box className="flex items-center justify-between">
          <InputLabel className="text-content-14 !text-[#162A4C]" htmlFor="gender">
            {t('gender')}
          </InputLabel>
          <RadioGroup defaultValue="male" name="gender" className="flex flex-wrap !flex-row">
            <FormControlLabel value="male" control={<RadioControl />} label={t('male')} />
            <FormControlLabel value="female" control={<RadioControl />} label={t('female')} />
          </RadioGroup>
        </Box>
        <Box className="flex items-center justify-between">
          <InputLabel className="text-content-14 !text-[#162A4C]" htmlFor="birthday">
            {t('birthday')}
          </InputLabel>
          <Box>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              name="birthday"
              dateFormat="dd/MM/yyyy"
              className="border-[1px] rounded-lg p-2 text-right"
              popperPlacement="top-end"
            />
          </Box>
        </Box>
        {(errorHandle?.response?.status === 404 || errorHandle?.response?.status === 400) && <Box className="text-center text-content-16 text-brand-1 mt-3">{t('exist_account')}</Box>}
        <Button type="submit" className="!bg-brand-1 !text-white !normal-case !text-content-18 !font-[500] w-[200px] !mt-2">
          {t('complete')}
        </Button>
      </Box>
    </Box>
  )
}

export default StepUpdateProfile
