import { Box, CircularProgress, Pagination } from '@mui/material'
import newsBgBotRight from 'assets/images/bgbotright.png'
import newsBgTopRight from 'assets/images/bgtopright.png'
import newBg from 'assets/images/news-bg.png'
import CustomBreadcrumbs from 'components/CustomBreadcrumbs'
import { useQueryParams } from 'hooks/useQueryParams'
import qs from 'qs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { GenericDataType, NewsApiService } from 'services'
import { constantParams } from 'utils/constants'
import ROUTES from 'utils/constants/routesConstant'
import { generateMediaLink } from 'utils/functions'
import CardNews from './components/CardNews'

export type NewsType = {
  _id: string
  title: string
  content: string
  link: string
  thumbnail: string
}

const NewsCategoryPage = () => {
  const { t } = useTranslation('home')
  const currentParams = useQueryParams()
  const history = useHistory()
  const { data, isLoading } = NewsApiService.useGet<GenericDataType<NewsType>>({
    params: { ...currentParams },
  })

  const totalPage = React.useMemo(() => {
    if (data) {
      return data?.data?.totalPage
    }
    return 0
  }, [data])

  const handleChange = (_: any, page: number) => {
    const newParams = { ...currentParams, page, limit: Number(currentParams?.limit || constantParams.limit) }
    history.push(ROUTES.NEWS + '?' + qs.stringify(newParams))
  }

  return (
    <Box className="w-full">
      <CustomBreadcrumbs />
      <Box className="bg-brand-1 container max-w-full text-white text-[20px] pt-10 pb-10">{t('land')}</Box>
      <Box className="max-w-[1440px] mx-auto px-14 py-10">
        <Box component={'img'} src={newBg} className="h-[392px] w-full object-cover" />
        {/* <Box className="w-full h-12">Thanh công cụ</Box> */}
        <Box className="flex flex-wrap w-full mt-6">
          {isLoading ? (
            <Box className="flex justify-center my-8 desktop:w-[calc(100%_-_325px)] h-full w-full">
              <CircularProgress />
            </Box>
          ) : (
            <Box className="desktop:w-[calc(100%_-_325px)] h-full">
              <Box className="flex flex-wrap gap-6">
                {data?.data?.result?.map((item, index) => (
                  <CardNews title={item.title} image={generateMediaLink(item.thumbnail)} key={index} onClick={() => history.push(`/news/${item._id}`)} />
                ))}
              </Box>
              <Box className="flex justify-center mt-8">
                <Pagination variant="outlined" count={totalPage} shape="rounded" onChange={handleChange} disabled={isLoading} />
              </Box>
            </Box>
          )}
          <Box className="hidden w-[325px] desktop:flex desktop:flex-col desktop:gap-4">
            <Box component={'img'} src={newsBgTopRight} className="w-full h-[325px]" />
            <Box component={'img'} src={newsBgBotRight} className="h-[820px] w-full object-cover" />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NewsCategoryPage
