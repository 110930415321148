import { Box } from '@mui/material'
import React from 'react'
import HouseDetail from './HouseDetail'
import VehicleDetail from './VehicleDetail'
import { ItemType } from 'utils/types'
import { lineVehicle, statusVehicle, typeCard, typeFuel, warrantyVehicle } from 'utils/constants'
import { useTranslation } from 'react-i18next'
import { ProductType } from 'utils/functions'

export enum DetailType {
  HOUSE = 1,
  VEHICLE = 2,
}

type Props = {
  data?: ItemType
}

const DetailInformation: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation('detail')
  const type = React.useMemo(() => {
    if (data?.type && ProductType(data?.type) === 'HOUSE') {
      return DetailType.HOUSE
    }
    return DetailType.VEHICLE
  }, [data?.type])

  const roomDetail = React.useMemo(() => {
    return {
      capacity: data?.capacity,
      bathroom: data?.n_bathroom,
      bedroom: data?.n_bedroom,
      livingroom: data?.n_livingroom,
    }
  }, [data])

  const properties = React.useMemo(() => {
    return {
      air_conditional: data?.air_conditional,
      garage: data?.garage,
      garden: data?.garden,
      kitchen: data?.kitchen,
      pool: data?.pool,
    }
  }, [data])

  const roomCounter = React.useMemo(() => {
    return {
      n_bathroom: data?.n_bathroom,
      n_bedroom: data?.n_bedroom,
      n_livingroom: data?.n_livingroom,
      n_toilet: data?.n_toilet,
    }
  }, [data])

  const vehicleDetail = React.useMemo(() => {
    return {
      type_of_vehicle_1: t(typeCard?.VEHICLE?.find((x) => x === data?.type)?.toLowerCase() || ''),
      vehicle_line: t(lineVehicle.find((x) => x === data?.vehicle_line)?.toLowerCase() || ''),
      n_km_traveled: data?.n_km_traveled,
      origin: data?.origin,
      tonnage: data?.tonnage,
      firms: data?.firms,
      n_year_manufacture: data?.n_year_manufacture,
      status: t(statusVehicle.find((x) => x === data?.status)?.toLowerCase() || ''),
      fuel: t(typeFuel.find((x) => x === data?.fuel)?.toLowerCase() || ''),
      warranty_policy: t(warrantyVehicle.find((x) => x === data?.warranty_policy)?.toLowerCase() || ''),
      license_plate: data?.license_plate,
    }
  }, [data, t])

  switch (type) {
    case DetailType.HOUSE:
      return (
        <Box className="bg-white rounded-xl p-8">
          <HouseDetail
            houseName={data?.name}
            address={data?.address_path}
            rating={5}
            latitude={data?.latitude}
            longitude={data?.longitude}
            roomDetail={roomDetail}
            properties={properties}
            roomCounter={roomCounter}
            description={data?.description}
          />
        </Box>
      )
    case DetailType.VEHICLE:
      return (
        <Box className="bg-white rounded-xl p-8">
          <VehicleDetail data={vehicleDetail} description={data?.description} />
        </Box>
      )
    default:
      return <></>
  }
}

export default DetailInformation
