import { Box, Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TitleWithRoute, { TitleWithRouteType } from 'components/TitleWithRoute'
import HaNoi from 'assets/images/hanoi.jpg'

type ButtonPlaceProps = {
  srcImage?: string
  name?: string
  href?: string
  desc?: string
}

const ButtonPlace: React.FC<ButtonPlaceProps> = ({ srcImage, name, href, desc }) => {
  return (
    <div className="block-process-order">
      <Box component={'img'} src={srcImage} alt="" className="" />
      <h3>{name}</h3>
      <p>{desc}</p>
    </div>
  )
}

const DiscoveryNearby = () => {
  const { t } = useTranslation('home')

  const listPlace = [
    {
      image: '/images/process-create.png',
      href: '/',
      name: 'Tạo đơn hàng',
      desc: 'Hàng hóa được chúng tôi kiểm tra rất kỹ tại kho và theo dõi sát sao tất cả quá trình vận chuyển.',
    },
    {
      image: '/images/process-send-order.png',
      href: '/',
      name: 'Gửi đơn hàng',
      desc: 'Thời gian hoàn thành 1 đơn hàng chỉ từ 5 đến 10 ngày, thời gian vận chuyển chỉ từ 2 đến 3 ngày.',
    },

    {
      image: '/images/process-revi-order.png',
      href: '/',
      name: 'Nhận đơn hàng',
      desc: 'Chúng tôi luôn giải quyết các thắc mắc của quý khách hàng kể cả ngoài giờ làm việc.',
    },
    {
      image: '/images/process-agree-order.png',
      href: '/',
      name: 'Chốt đơn hàng',
      desc: 'Thanh toán đơn hàng, nạp tiền tài khoản ngân hàng hoặc Alipay, Wechat',
    },
    {
      image: '/images/process-payment.png',
      href: '/',
      name: 'Thanh toán',
      desc: 'Hệ thống ví điện tử giúp giao dịch nhanh chóng, tích điểm nhận nhiều ưu đãi.',
    },
    {
      image: '/images/process-create.png',
      href: '/',
      name: 'Khác',
      desc: 'Hàng hóa được chúng tôi kiểm tra rất kỹ tại kho và theo dõi sát sao tất cả quá trình vận chuyển.',
    },
  ]

  return (
    <Box className="mt-4">
      <TitleWithRoute buttonText={t('go_discover_now')} href="/" title={t('discovery_title')} type={TitleWithRouteType.BACKGROUND_BUTTON} />
      <Box className="flex flex-wrap justify-around mb-6">
        {listPlace.map((item, index) => (
          <ButtonPlace name={item.name} srcImage={item.image} href={item.href} key={index} desc={item.desc} />
        ))}
      </Box>
    </Box>
  )
}

export default DiscoveryNearby
