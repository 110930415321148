import { AttachMoneyOutlined, BadgeOutlined, CalendarTodayOutlined, PeopleOutlineOutlined } from '@mui/icons-material'
import { Box, Button, InputAdornment, OutlinedInput } from '@mui/material'
import { ACTION_TYPE, useLoginModalDispatch } from 'context'
import useAuth from 'hooks/useAuth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateDiff, convertPrice, getDateString } from 'utils/functions'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { GenericDataType, OrdersApiService } from 'services'
import { ItemType } from 'utils/types'
import { useSnackbar } from 'notistack'
import { AxiosError } from 'axios'
import Stack from '@mui/material/Stack'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import TextField from '@mui/material/TextField'

type Props = {
  data?: ItemType
}

type People = {
  [key: string]: number
  adult: number
  children: number
}

type DataPre = {
  commodity_id: string
  commodity_type: string
  to_date: string
  from_date: string
  discount: number
  type_rental: string
  price: number
  deposit_percent: number
  province: string
  district: string
  ward: string
  total: number
  deposit_money: number
}

export type ErrorType = {
  data: {
    error_code: string
    message: string
  }
}

const BookingForm: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation('detail')
  const dispatch = useLoginModalDispatch()
  const { token } = useAuth()
  const [startDate, setStartDate] = React.useState<Date | null>(new Date())
  const [endDate, setEndDate] = React.useState<Date | null>(new Date())
  const [people, setPeople] = React.useState<People>({ adult: 0, children: 0 })
  const { enqueueSnackbar } = useSnackbar()

  const { data: dataPrePay, isLoading, mutate } = OrdersApiService.usePost<GenericDataType<DataPre>>({ url: '/pre' })
  const { data: dataRequest, isLoading: isLoadingRequest, mutate: mutateRequest, error: errorRequest } = OrdersApiService.usePost<GenericDataType<DataPre>>({ url: '' })

  const totalPricePrePay = React.useMemo(() => {
    const price = dataPrePay?.data?.result[0]?.deposit_money || 0
    const stringPrice = convertPrice(price)
    return stringPrice
  }, [dataPrePay?.data?.result])

  const percentPerMonth = React.useMemo(() => {
    return dataPrePay?.data?.result[0]?.deposit_percent || 0
  }, [dataPrePay?.data?.result])

  const totalPrice = React.useMemo(() => {
    return convertPrice(dataPrePay?.data?.result[0]?.total || 0)
  }, [dataPrePay?.data?.result])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!token) {
      dispatch({ type: ACTION_TYPE.INIT, payload: { isLoggedIn: true } })
      return
    }
    if (startDate && endDate) {
      const requestProps = {
        commodity_id: data?._id,
        commodity_type: 'HOUSE',
        from_date: getDateString(startDate, 'yyyy-mm-dd'),
        to_date: getDateString(startDate, 'yyyy-mm-dd'),

        n_renter: people.adult + people.children,
        n_adult: people.adult,
        n_child: people.children,
        from_time: '00:00',
        to_time: '00:00',
      }
      mutateRequest({
        data: requestProps,
      })
    }
  }

  const handleChangeInput = (key: string, value: number) => {
    if (value < 0) {
      setPeople((prev) => ({ ...prev, [key]: 0 }))
    } else setPeople((prev) => ({ ...prev, [key]: value }))
  }

  const handleChangeStartDate = (date: Date | null) => {
    if (date && endDate) {
      if (endDate?.getTime() < date?.getTime()) {
        setStartDate(date)
        setEndDate(date)
      }
    }
  }

  React.useEffect(() => {
    if (dataRequest && dataRequest?.data?.result) {
      enqueueSnackbar(t('request_success'), { variant: 'success' })
    }
    if (errorRequest) {
      const errorResponse = errorRequest as AxiosError<ErrorType>
      if (errorResponse?.response?.data?.data?.error_code === 'ORDER_COMMODITY_RENTED') {
        enqueueSnackbar(t('request_error_exist_renter'), { variant: 'error' })
      }
    }
  }, [dataRequest, enqueueSnackbar, errorRequest, t])

  React.useEffect(() => {
    if (startDate && endDate) {
      mutate({
        data: {
          commodity_id: data?._id,
          commodity_type: 'HOUSE',
          to_date: getDateString(startDate, 'yyyy-mm-dd'),
          from_date: getDateString(startDate, 'yyyy-mm-dd'),
        },
      })
    }
  }, [data?._id, endDate, mutate, startDate])

  console.log('data ---', data)

  return (
    <Box className="mt-12">
      <Box className="text-content-12 text-black-1">{t('pre_money_for_month')}</Box>
      <Box className="text-brand-1 font-bold text-content-16">
        {totalPrice} {data?.type_rental === 'DAY' ? t('per_day') : t('per_month')}
      </Box>
      <Box className="text-content-12 font-bold">
        <AttachMoneyOutlined className="!text-access-green !text-content-16" />
        {t('agree_pre_pay')}
      </Box>
      <Box component={'form'} className="flex flex-col gap-2 mt-4" onSubmit={handleSubmit}>
        <Box className="text-content-14 font-bold">{t('time_to_stay')}</Box>
        <Box className="flex w-full border-[1px] p-2 rounded">
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
            <CalendarTodayOutlinedIcon sx={{ color: '#adb3bc', fontSize: '1em' }} />
            <DatePicker
              selected={startDate}
              onChange={handleChangeStartDate}
              selectsStart
              startDate={startDate}
              minDate={new Date()}
              endDate={endDate}
              disabled={isLoading || isLoadingRequest}
              dateFormat="dd/MM/yyyy"
              name="start-date"
              className="tablet:w-[100%] text-content-14 border-0 rounded-lg px-2 text-center outline-none"
            />
            <span> - </span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              disabled={isLoading || isLoadingRequest}
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
              minDate={startDate}
              name="end-date"
              className="tablet:w-[100%] text-content-14 border-0 rounded-lg px-2 text-center outline-none"
            />
          </Stack>
        </Box>
        <Box className="rounded-lg flex items-center border-[1px] pl-2 pr-2 pt-1 pb-1 rounded">
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
              <InputAdornment position="start">
                <PeopleOutlineOutlined className="!text-icon !text-content-16 mr-2" />
              </InputAdornment>
              <TextField
                style={{ textAlign: 'center' }}
                variant="standard"
                disabled={isLoading || isLoadingRequest}
                name={'adult'}
                onChange={(e) => handleChangeInput('adult', Number(e.target.value))}
                type="number"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={people.adult}
                size="small"
              />
              <InputAdornment position="end">
                <Box className="!text-content-12">{t('adult')}</Box>
              </InputAdornment>
            </Stack>

            {/* <OutlinedInput
            className="tablet:w-[100%] border-none outline-none"
            startAdornment={
              <InputAdornment position="start">
                <PeopleOutlineOutlined className="!text-icon !text-content-16 mr-2" />
              </InputAdornment>
            }
            disabled={isLoading || isLoadingRequest}
            name={'adult'}
            onChange={(e) => handleChangeInput('adult', Number(e.target.value))}
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={people.adult}
            endAdornment={}
          /> */}
            <span>|</span>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
              <InputAdornment position="start">
                <PeopleOutlineOutlined className="!text-icon !text-content-16 mr-2" />
              </InputAdornment>
              <TextField
                sx={{ textAlign: 'center' }}
                variant="standard"
                disabled={isLoading || isLoadingRequest}
                onChange={(e) => handleChangeInput('children', Number(e.target.value))}
                name={'children'}
                value={people.children}
                type="number"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                size="small"
              />
              <InputAdornment position="end">
                <Box className="!text-content-12">{t('children')}</Box>
              </InputAdornment>
            </Stack>
            {/* <OutlinedInput
              className="tablet:w-[100%]"
              startAdornment={
                <InputAdornment position="start">
                  <PeopleOutlineOutlined className="!text-icon !text-content-16 mr-2" />
                </InputAdornment>
              }
              disabled={isLoading || isLoadingRequest}
              onChange={(e) => handleChangeInput('children', Number(e.target.value))}
              name={'children'}
              value={people.children}
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <Box className="!text-content-12">{t('children')}</Box>
                </InputAdornment>
              }
            /> */}
          </Stack>
        </Box>
        <Box className="mt-2 border-t-2 pt-3">
          <Box className="text-content-12 font-bold">{t('prepay_per_month', { percent: percentPerMonth })}</Box>
          <Box className="text-content-10 text-grey-1">{t('prepay_rule')}</Box>
          <Box className="text-content-10 text-grey-1">{totalPricePrePay || 0} đ</Box>
        </Box>
        <Button
          type="submit"
          className="h-8 !rounded-lg !text-white !normal-case !bg-brand-1 !font-[600] !text-content-12 !mb-3 !mt-4 disabled:!bg-grey-1 disabled:!cursor-not-allowed"
          disabled={isLoading || isLoadingRequest || (!people.adult && !people.children)}
        >
          {t('send_request_book_room')}
        </Button>
      </Box>
    </Box>
  )
}

export default BookingForm
