import { AxiosRequestConfig } from 'axios'
import { UseInfiniteQueryOptions, UseQueryOptions, useInfiniteQuery, useMutation, useQuery } from 'react-query'
import {
  AUTH_API_ENDPOINT,
  COMMENT_ENDPOINT,
  COMODITY_IMAGE_API_ENDPOINT,
  DEFAULT_ENDPOINT,
  DUMMY_API_ENDPOINT,
  FAVORITE_API_ENDPOINT,
  FLASH_SALE_API_ENDPOINT,
  FLASH_SALE_COMING_API_ENDPOINT,
  HOUSE_API_ENDPOINT,
  HOUSE_FAVORITE_API_ENDPOINT,
  HOUSE_HOT_API_ENDPOINT,
  NEWS_API_ENDPOINT,
  ORDER_ENDPOINT,
  OTP_ENDPOINT,
  REVIEW_API_ENDPOINT,
  ROLES_ENDPOINT,
  USER_API_ENDPOINT,
  VEHICLE_API_ENDPOINT,
  VEHICLE_SEARCH_API_ENDPOINT,
} from 'utils/constants/apiConstant'
import httpClient, { Response } from './httpClient'

export type ApiParamsProps = { [key: string]: string | number | string[] | number[] | undefined | boolean }
interface ApiServiceProps {
  url?: string
  params?: ApiParamsProps
  data?: any
  id?: string | number
  config?: AxiosRequestConfig
}

interface ApiQueryServiceProps<TOptions extends unknown> extends ApiServiceProps {
  key?: string
  options?: TOptions
}

export enum API_SERVICE_TYPE {
  AUTH,
  COMMODITY_IMAGE,
  DUMMY,
  FAVORITE,
  FLASH_SALE,
  FLASH_SALE_COMING,
  HOUSE,
  HOUSE_HOT,
  HOUSE_FAVORITE,
  REVIEW,
  USER,
  VEHICLE,
  VEHICLE_SEARCH,
  NEWS_UNIQUE,
  OTP,
  ROLES,
  DEFAULT,
  ORDER,
  COMMENT,
}

const getApiEndpoint = ({ serviceType }: { serviceType?: API_SERVICE_TYPE }) => {
  switch (serviceType) {
    case API_SERVICE_TYPE.AUTH:
      return AUTH_API_ENDPOINT
    case API_SERVICE_TYPE.COMMODITY_IMAGE:
      return COMODITY_IMAGE_API_ENDPOINT
    case API_SERVICE_TYPE.DUMMY:
      return DUMMY_API_ENDPOINT
    case API_SERVICE_TYPE.FAVORITE:
      return FAVORITE_API_ENDPOINT
    case API_SERVICE_TYPE.FLASH_SALE:
      return FLASH_SALE_API_ENDPOINT
    case API_SERVICE_TYPE.FLASH_SALE_COMING:
      return FLASH_SALE_COMING_API_ENDPOINT
    case API_SERVICE_TYPE.HOUSE:
      return HOUSE_API_ENDPOINT
    case API_SERVICE_TYPE.HOUSE_HOT:
      return HOUSE_HOT_API_ENDPOINT
    case API_SERVICE_TYPE.HOUSE_FAVORITE:
      return HOUSE_FAVORITE_API_ENDPOINT
    case API_SERVICE_TYPE.REVIEW:
      return REVIEW_API_ENDPOINT
    case API_SERVICE_TYPE.USER:
      return USER_API_ENDPOINT
    case API_SERVICE_TYPE.VEHICLE:
      return VEHICLE_API_ENDPOINT
    case API_SERVICE_TYPE.VEHICLE_SEARCH:
      return VEHICLE_SEARCH_API_ENDPOINT
    case API_SERVICE_TYPE.NEWS_UNIQUE:
      return NEWS_API_ENDPOINT
    case API_SERVICE_TYPE.OTP:
      return OTP_ENDPOINT
    case API_SERVICE_TYPE.ROLES:
      return ROLES_ENDPOINT
    case API_SERVICE_TYPE.DEFAULT:
      return DEFAULT_ENDPOINT
    case API_SERVICE_TYPE.ORDER:
      return ORDER_ENDPOINT
    case API_SERVICE_TYPE.COMMENT:
      return COMMENT_ENDPOINT
    default:
      return
  }
}

interface ApiMethodProps {
  get: <T>(props: ApiServiceProps) => Promise<Response<T>>
  post: <T>(props: ApiServiceProps) => Promise<Response<T>>
  put: <T>(props: ApiServiceProps) => Promise<Response<T>>
  patch: <T>(props: ApiServiceProps) => Promise<Response<T>>
  delete: <T>(props: ApiServiceProps) => Promise<Response<T>>
}

export type GenericDataType<T> = {
  currentPage: number
  limit: number
  result: T[]
  total: number
  totalPage: number
}

export const GetApiMethodInstance = (serviceType: API_SERVICE_TYPE): ApiMethodProps => {
  return {
    get: async <T>({ url = '', params, config }: ApiServiceProps) => {
      return httpClient.get<Response<T>>(`${getApiEndpoint({ serviceType })}${url}`, {
        ...config,
        params: params,
      })
    },
    post: async <T = unknown>({ url = '', data, params }: ApiServiceProps) => {
      return httpClient.post<Response<T>>(`${getApiEndpoint({ serviceType })}${url}`, data, {
        params: params,
      })
    },
    put: async ({ url = '', data, params }: ApiServiceProps) => {
      return httpClient.put(`${getApiEndpoint({ serviceType })}${url}`, data, {
        params: params,
      })
    },
    patch: async ({ url = '', data, params }: ApiServiceProps) => {
      return httpClient.patch(`${getApiEndpoint({ serviceType })}${url}`, data, {
        params: params,
      })
    },
    delete: async ({ url = '', id, params }: ApiServiceProps) => {
      return httpClient.delete(`${getApiEndpoint({ serviceType })}${url}/${id}`, {
        params: params,
      })
    },
  }
}

export class ApiService {
  constructor(serviceType: API_SERVICE_TYPE) {
    this.apiMethod = GetApiMethodInstance(serviceType)
  }

  apiMethod: ApiMethodProps
  useGet = <T>({ url = '', params, options, config }: ApiQueryServiceProps<Omit<UseQueryOptions<Response<T>>, 'queryKey' | 'queryFn'>>) => {
    return useQuery([url, params], () => this.apiMethod.get<T>({ url: url, params: params, config }), options)
  }
  useInfinite = <T>({ url = '', params, options }: ApiQueryServiceProps<Omit<UseInfiniteQueryOptions<Response<T>>, 'queryKey' | 'queryFn'>>) => {
    return useInfiniteQuery([url, params], (pageParam: any) => this.apiMethod.get<T>({ url: url, params: { ...params, ...pageParam.pageParam } }), options)
  }
  usePost = <T = unknown>({ url = '' }: ApiServiceProps) => {
    return useMutation(({ data }: { data: Record<string, any> }) => this.apiMethod.post<T>({ url: url, data: data }))
  }
  usePut = <T = unknown>({ url = '' }: ApiServiceProps) => {
    return useMutation(({ data }: { data: Record<string, any> }) => this.apiMethod.put<T>({ url, data }))
  }
  usePatch = <T = unknown>({ url = '', params }: ApiServiceProps) => {
    return useMutation(({ data }: { data: Record<string, any> }) => this.apiMethod.patch<T>({ url: url, data: data, params: params }))
  }
  useDelete = ({ url = '', params }: ApiServiceProps) => {
    return useMutation((id?: string | number) => this.apiMethod.delete({ url: url, id: id, params: params }))
  }
}

export const AuthApiService = new ApiService(API_SERVICE_TYPE.AUTH)
export const CommodityImageApiService = new ApiService(API_SERVICE_TYPE.COMMODITY_IMAGE)
export const DummyApiService = new ApiService(API_SERVICE_TYPE.DUMMY)
export const FavoriteApiService = new ApiService(API_SERVICE_TYPE.FAVORITE)
export const FlashSaleApiService = new ApiService(API_SERVICE_TYPE.FLASH_SALE)
export const FlashSaleComingApiService = new ApiService(API_SERVICE_TYPE.FLASH_SALE_COMING)
export const HouseApiService = new ApiService(API_SERVICE_TYPE.HOUSE)
export const HouseHotApiService = new ApiService(API_SERVICE_TYPE.HOUSE_HOT)
export const HouseFavoriteApiService = new ApiService(API_SERVICE_TYPE.HOUSE_FAVORITE)
export const ReviewApiService = new ApiService(API_SERVICE_TYPE.REVIEW)
export const UserApiService = new ApiService(API_SERVICE_TYPE.USER)
export const VehicleApiService = new ApiService(API_SERVICE_TYPE.VEHICLE)
export const VehicleSearchApiService = new ApiService(API_SERVICE_TYPE.VEHICLE_SEARCH)
export const BDSNewsApiService = new ApiService(API_SERVICE_TYPE.NEWS_UNIQUE)
export const OTPApiService = new ApiService(API_SERVICE_TYPE.OTP)
export const RolesApiService = new ApiService(API_SERVICE_TYPE.ROLES)
export const DefaultApiService = new ApiService(API_SERVICE_TYPE.DEFAULT)
export const NewsApiService = new ApiService(API_SERVICE_TYPE.NEWS_UNIQUE)
export const OrdersApiService = new ApiService(API_SERVICE_TYPE.ORDER)
export const CommentApiService = new ApiService(API_SERVICE_TYPE.COMMENT)
